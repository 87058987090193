import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

import { Tabs, Tab, Modal, Button, Dropdown, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import '../css/styles.css';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import { Scrollbars } from 'react-custom-scrollbars';

import CountUp from 'react-countup';

import coin from "../images/coin.png"

import pgm1 from "../images/pgm1.png"
import pgm2 from "../images/pgm2.png"
import pgm3 from "../images/pgm3.png"
import pgm4 from "../images/pgm4.png"
import globe_bg from "../images/globr_bg.png"

import imgleft from "../images/img-left-flower.png"
import imgright from "../images/img-right-flower.png"
import himg3 from "../images/h-img3.png"
import yellow from "../images/yellowball.png"
import coin1 from "../images/coin1.png"
import speaker from "../images/speaker.png"








import logo_tiger from "../images/logo_tiger.png"

import banner_tiger from "../images/banner_tiger.png"

import tat_logo from "../images/tat_logo.png"
import txt_logo from "../images/txt_logo.png"
import meta_icon from "../images/meta_icon.png"

import person_1 from "../images/person.png"





import shield from "../images/welcome/shield.png"
import bucket from "../images/welcome/bucket.png"
import cup from "../images/welcome/cup.png"

import img1 from "../images/welcome/img1.png"
import img1color from "../images/welcome/img1color.png"


import token1 from "../images/token-1.png"
import token2 from "../images/token-2.png"
import token3 from "../images/token-3.png"
import token4 from "../images/token-4.png"
import token5 from "../images/token-5.png"
import token6 from "../images/token-6.png"
import token7 from "../images/token-7.png"

import pdffile from "../images/whitepaper.pdf"



import testimonial from "../images/testimonial.png"

// const API_URL = 'https://metaswap.click';

const API_URL = 'http://localhost:2053';







class Landing extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            tvlvalue: "",
            txt:"",
            tat:"",
            responsive:{
                0: {
                    items: 1,
                },
                320: {
                    items: 1,
                },
                767: {
                    items: 2,
                },
                992: {
                    items: 2,
                },
                1200: {
                    items: 3,
                }
            },

        }
    }

  
    componentDidMount() {
        // this.getDatafromDB()
        this.setState({ show1: true });
    }
    showDesc(id)
    {

        document.getElementById("moretext_"+id).classList.toggle('expanded');
        if (document.getElementById("more_a_"+id).innerHTML == "Read More") {
            document.getElementById("more_a_"+id).innerHTML ="Read Less";
          } else {
            document.getElementById("more_a_"+id).innerHTML ="Read More";

          }

        
              
        
    }
   
    changeIcon(key)
    {
            // console.log("key",key);
            for(var i=0;i<4;i++)
            {
                document.getElementById("icon_"+i).classList.remove("rotate_ico");
            }
            document.getElementById("icon_"+key).classList.toggle("rotate_ico");
    }

    // getDatafromDB = async()=>{
    //     const url = `${API_URL}/api/totalvaluelocked`;
	// 	axios.get(url).then((res) => {
    //         console.log("backdata",res);
	// 		 this.setState({ tvlvalue: res.data[0].value });
    //          console.log("data vlaim1 : ",res.data[0].value)
	// 	}).catch((err) => {
	// 		if (err) console.log(err);
	// 	});
    //     const url1 = `${API_URL}/api/price/txt`;
	// 	axios.get(url1).then((res) => {
	// 		 this.setState({ txt: (Math.round((res.data.price) * 100) / 100).toFixed(3) });
    //          console.log("data vlaim : ",(res.data.price))
	// 	}).catch((err) => {
	// 		if (err) console.log(err);
	// 	});
    //     const url2 = `${API_URL}/api/price/tat`;
	// 	axios.get(url2).then((res) => {
	// 		 this.setState({ tat: (Math.round((res.data.price) * 100) / 100).toFixed(3) });
    //          console.log("data vlaim : ",(res.data.price).toFixed(3))
	// 	}).catch((err) => {
	// 		if (err) console.log(err);
	// 	});
    // }
    
    render() {
        // if (window.innerWidth < 767) {
        //     alert();
        //    }
      const location = this.props.location.pathname.split('/')[1];
      const { show1 } = this.state;
      const handleClose = () =>   this.setState({ show1: false });;

      
    //   let connectModalClose = () => this.setState({connectModalShow:false})
    //   console.log("location",location);
       
	return (
        <div>
            <Header location={location} />

            {/* modal welcome */}
            {/* <Modal size="lg" className="welcome-modal" centered dialogClassName="modal-90w" show={show1} onHide={handleClose}>

                        <Modal.Body className="p-0">
                        <Scrollbars className="modal_scroolbar" style={{ height: 400 }}>
                            <div className="modal-cont">
                           
                               <div className="modal_banner">
                                   </div>
                                   <div className="shield text-center">
                                       <div className="shiled_img">
                                   <img src={shield} alt="Micro-lending" className="img-fluid"/>
                                   <div className="shiled_desc">
                                       <p className="invite_text">Invitation From</p>
                                       <p className="invite_peson">Giaus Chibueze</p>

                                       </div>
                                   </div>
                                       </div>
                                       <div className="row">
                                           <div className="col-11 col-md-10 col-lg-6 mx-auto">
                                           <div className="banner_main_txt container">
                                           <p className="welcome_txt">
                                               <span className="grey_txt_theme">Welcome to</span>
                                               <span className="blue_txt_theme">MetaSwap</span>

                                           </p>
                                           <p className="wleoc_desc">Here are what you can do to make profit from the Crypto Ecosystem.</p>
                                           </div>
                                               </div>
                                           </div>
                                           <div className="row">
                                               <div className="col-11 col-md-10 mx-auto">
                                               <div className="container">
                                               <div className="row">
                                               <div className="col-12 col-lg-6 mb-4">
                                                   <div className="card card_welcome hover-anime">
                                                       <div className="card-body pt-5">
                                                           <div className="text-center">
                                                               <div className="img_anim_sec">
                                                               <div className="img_sec_1 text-center grey_bg_card anime-bg">
                                                                    <img src={jug} alt="Micro-lending" className="img-fluid jug"/>
                                                                    <img src={bucket1} alt="Micro-lending" className="img-fluid bucket"/>
                                                                    <img src={dots} alt="Micro-lending" className="img-fluid dots"/>
                                                               </div>

                                                              

                                                              </div>
                                                              <div className="desc_view_sec">
                                                              <p className="title_card_wel">Provide Liquidity!</p>
                                                            <p className="desc_card_wel mb-1">Provide Liquidity of TXT-BNB, TAT-BNB TXT-BUSD</p>
                                                            <p className="desc_card_wel">To earn (1,000% APR/ROI) based on market rate.</p>
                                                            </div>
                                                            <a href="#" className="btn btn_grey_new btn-block">Provide Liquidity</a>
                                                            
                                                              </div>
                                                           </div>
                                                       </div>
                                                   </div>

                                                   <div className="col-12 col-lg-6 mb-4">
                                                   <div className="card card_welcome hover-anime">
                                                       <div className="card-body pt-5">
                                                           <div className="text-center">
                                                               <div className="img_anim_sec">
                                                               <div className="grey_bg_card anime-bg">
                                                                    <img src={box} alt="Micro-lending" className="img-fluid box"/>
                                                                    <img src={money} alt="Micro-lending" className="img-fluid money"/>
                                                                    <img src={door} alt="Micro-lending" className="img-fluid door"/>
                                                                    <img src={handle} alt="Micro-lending" className="img-fluid handle"/>
                                                                  
                                                                   </div>

                                                              </div>
                                                              <div className="desc_view_sec">
                                                              <p className="title_card_wel">Do Locked Staking to Earn</p>
                                                            <p className="desc_card_wel">Lock your TATCOIN or TXT for 90 days to Earn over (1,000% APR/ROI)</p>
                                                            </div>
                                                            <a href="#" className="btn btn_grey_new btn-block">Do Locked Staking</a>
                                                            
                                                              </div>
                                                           </div>
                                                       </div>
                                                   </div>

                                                  

                                                   <div className="col-12 col-lg-6 mb-4">
                                                   <div className="card card_welcome hover-anime">
                                                       <div className="card-body pt-5">
                                                           <div className="text-center">
                                                               <div className="img_anim_sec">
                                                               <div className="grey_bg_card br-30 anime-bg">
                                                               <img src={swap1} alt="Micro-lending" className="img-fluid swap1"/>
                                                               <img src={swap2} alt="Micro-lending" className="img-fluid swap2"/>
                                                               <img src={scoin1} alt="Micro-lending" className="img-fluid scoin1"/>
                                                               <img src={scoin2} alt="Micro-lending" className="img-fluid scoin2"/>
                                                               <img src={scoin3} alt="Micro-lending" className="img-fluid scoin3"/>
                                                                  
                                                                   </div>

                                                              </div>
                                                              <div className="desc_view_sec_1">
                                                              <p className="title_card_wel">Ordinary Staking</p>
                                                            <p className="desc_card_wel">Stake your TATCOIN to Earn (1,000% APR/ROI) and you can unstake at anytime</p>
                                                            </div>
                                                            <a href="#" className="btn btn_grey_new btn-block">Stake</a>
                                                           
                                                              </div>
                                                           </div>
                                                       </div>
                                                   </div>

                                                   <div className="col-12 col-lg-6 mb-4">
                                                   <div className="card card_welcome  hover-anime">
                                                       <div className="card-body pt-5">
                                                           <div className="text-center">
                                                               <div className="img_anim_sec">
                                                               <div className="grey_bg_card anime-bg">
                                                               <img src={stack} alt="Micro-lending" className="img-fluid stack"/>
                                                               <img src={disc1} alt="Micro-lending" className="img-fluid disc1 disc"/>
                                                               <img src={disc2} alt="Micro-lending" className="img-fluid disc2 disc"/>
                                                               <img src={disc3} alt="Micro-lending" className="img-fluid disc3 disc"/>
                                                               <img src={disc4} alt="Micro-lending" className="img-fluid disc4 disc"/>

                                                                  
                                                                   </div>

                                                              </div>
                                                              <div className="desc_view_sec_1">
                                                              <p className="title_card_wel">Swap/BUY COINS</p>
                                                            <p className="desc_card_wel">Swap your BNB-TAT, TXT-BUSD etc</p>
                                                            </div>
                                                            <a href="#" className="btn btn_grey_new btn-block">Swap</a>
                                                            
                                                              </div>
                                                           </div>
                                                       </div>
                                                   </div>

                                                   
                                                   <div className="col-12 col-md-12 mb-4">
                                                   <div className="card card_welcome hover-anime">
                                                       <div className="card-body pt-5">
                                                           <div className="text-center">
                                                               <div className="img_anim_sec">
                                                               <img src={glow} alt="Micro-lending" className="img-fluid glow"/>
                                                               <div className="anime-bg">
                                                              
                                                               <img src={treasure} alt="Micro-lending" className="img-fluid treasure"/>
                                                               <img src={tcoin1} alt="Micro-lending" className="img-fluid tcoin1"/>
                                                               <img src={tcoin2} alt="Micro-lending" className="img-fluid tcoin2"/>
                                                               <img src={tcoin3} alt="Micro-lending" className="img-fluid tcoin3"/>
                                                               <img src={tcoin4} alt="Micro-lending" className="img-fluid tcoin4"/>
                                                               <img src={tcoin5} alt="Micro-lending" className="img-fluid tcoin5"/>
                                                               <img src={tcoin6} alt="Micro-lending" className="img-fluid tcoin6"/>
                                                               <img src={tcoin7} alt="Micro-lending" className="img-fluid tcoin7"/>
                                                               <img src={tcoin8} alt="Micro-lending" className="img-fluid tcoin8"/>

                                                                  
                                                                   </div>

                                                              </div>
                                                              <p className="title_card_wel text-center">Join TatEarn</p>
                                                            <p className="desc_card_wel text-center">Earn free crypto and share with friends.</p>
                                                            <a href="#" className="btn btn_grey_new">Join TatEarners</a>
                                                              </div>
                                                           </div>
                                                       </div>
                                                   </div>

                                                   
                                               </div>
                                               </div>
                                                   </div>
                                               </div>

                                               <div className="row">
                                               <div className="col-12 col-md-10 mx-auto">
                                               <div className="container">
                                                   <div className="row pb-3">
                                                   <div className="col-12 col-md-12 mb-4 mt-4">
                                                   <div className="card card_welcome card_guidance">
                                                       <div className="card-body">
                                                           <div className="text-center">
                                                               <div className="row">
                                                                   <div className="col-12 col-sm-8 col-lg-6 mx-auto">
                                                                   <p className="title_card_yel text-center mb-1">Need Guidance?</p>
                                                            <p className="text-white-new text-center font_14">Join this Telegram Group and Ask Any Questions.</p>

                                                            <a href="https://telegram.me/joinchat/n0YGQTuVWw02N2M0" target="_blank" className="btn btn_tele"> <span className="fa fa-paper-plane mr-2"></span> Join Now</a>
                                                                       </div>
                                                                   </div>
                                                             
                                                            
                                                              </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                                       </div>
                                                   </div>
                                                   </div>
                                                   </div>
                                       
                                              
                            </div>
                            </Scrollbars>
                        </Modal.Body>
                   
                    </Modal> */}

            {/* end modal welcome */}
            <div id="whole_sec">
                <section id="banner">
              
                    <div className="banner_bg">
                    {/* <img src={coin1} alt="Micro-lending" className="img-fluid coin1"/>
                    <img src={coin1} alt="Micro-lending" className="img-fluid coin2"/> */}
                    <div className="container container_custom">
                        <div className="banner_text_padding">
                            <div className="row align-items-center pad_top_new">
                                <div className="col-lg-7 col-md-12 text_centernew">
                                       {/* <div className="logo_banner"></div> */}
                                       <p className="banner_sub_text logo_txt_color">Welcome to MetaSwap</p>
                                       {/* <p className="banner_sub_text text-white tetx_new">${this.state.tvlvalue}</p> */}
                                       <p className="font_land_des">Total Value Locked (TVL) Across All LPs and Pools </p> 
                                            <p className="tat_desc">A Decentralized Exchange on Binance Smart Chain.</p>
                                            <p className="tat_desc">Buy, Sell, Swap one token for the other.</p>
                                            <p className="tat_desc">Earn residual income farming, staking your Favourite Coins.</p>
                                       
                                       <div className="row jus_cen_new">
                                           <div className="col-12 col-md-10">
                                               
                                            
                                            </div>
                                        </div>
                                        <div className="btn_sec">
                                            {/* <button className="btn_whit_solid btn mr-3">
                                       <img src={meta_icon} alt="Micro-lending" className="img-fluid btn_white_icon"/>
                                    <span>MSWA: ${this.state.txt}</span>
                                            </button> */}

                                            {/* <button className="btn_whit_solid_outline btn">
                                       <img src={meta_icon} alt="Micro-lending" className="img-fluid btn_white_icon"/>
                                    <span>MSWA: ${this.state.txt}</span>
                                            </button> */}
                                           <div className="btn_sec">
                                            <a href="https://app.metaswap.click" className="btn_gradient btn_navbar_grad mr-3"><span>Trade Now</span></a>
                                            </div>
                                            {/* <a href={pdffile} target="_blank" className="btn-link">WHITE PAPER</a> */}

                                        </div>
                                </div>
                                {/* <div className="col-lg-6 d-none d-lg-block">
                                    <div className="text-center">
                                       <div class="ban-img">
                                       <img src={banner_tiger} alt="Micro-lending" className="my-4 img-fluid banner_tig"/>
                                       </div> 
                                    </div>
                                </div> */}
                            </div>
                     
                        </div>
                    </div>
                    </div>
                </section>
               
                </div>
                <div>
           
                </div>
                <section className="bg_purple_layer_1 why-swap gray-bg pt-lg-5 d-block d-md-none">
                <div className="container container_custom">


                   <p className="why_text_tas title_text_why">Why MetaSwap?</p>
                    <div className="row mt-4 pt-3 justify-content-center">
                      <div className="col-lg-12 col-md-12">
                          <div className=" ">
            <OwlCarousel className="owl-theme" nav={false} margin={20} autoplay ={false} responsive={this.state.responsive} dots={true}>
                <div className="">
                    <div class="h-card">
                        <div className="card project_card h-100 white-bg">
                            <div className="card-body">
                                <p className=" text-blue head">For Projects</p>
                                <p class=" parg"> The easiest way to launch, list, and build liquidity for your
                                  project token from start. No expensive listing fee and because the protocol is 
                                  non-custodial and decentralized the process is fast with fewer requiremants.
                               </p>
                            </div>
                            </div>
                            </div>
                        </div>
                        
                        <div className="">
                          <div class="h-card">
                            <div className="card project_card h-100 white-bg">
                               <div className="card-body">
                                <p className="text-blue head">For Users</p>
                                <p class="parg">
                                    The fastest way to trade and farm tokens with low transaction fees. Earn high APY when you contribute liquidity to a pool. Put your token to work by staking to support and get rewarded by your favorite projects.
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                        </OwlCarousel>
                        </div>
                        </div>
                    </div>
                    </div>
            </section>
            <div className='sec_last'>
             <section className="bg_purple_layer_1 why-swap gray-bg pt-5 for-web d-none d-md-block">
                <div className="container container_custom">
                    <p className="why_text_tas title_text_why">Why MetaSwap?</p>
                    <div className="row mt-4 pt-3 justify-content-center">
                      <div className="col-lg-12 col-md-12">
                          <div className="row justify-content-between">
                        <div className="col-12 col-md-6 col-lg-5 col-lg-5-custon mb-4 ">
                            <div class="h-card">
                            <img src={imgleft} alt="img" className="h-img1 h-img"/>
                            <div className="card project_card h-100 white-bg">
                                <div className="card-body">
                                    {/* <div class="hover-img">
                                        <img src={himg2} alt="img" className="h-img2 h-img"/>
                                        <img src={himg3} alt="img" className="h-img3 h-img"/>
                                        <img src={himg3} alt="img" className="h-img4 h-img"/>
                                    </div> */}
                                <p className="text-blue head">For Projects</p>
                                <p class="parg"> The easiest way to launch, list, and build liquidity for your
                                  project token from start. No expensive listing fee and because the protocol is 
                                  non-custodial and decentralized the process is fast with fewer requirements.
                               </p>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 col-lg-5-custon mb-4">
                        <div class="h-card">
                            <img src={imgright} alt="img" className="h-img5 h-img"/>
                            <div className="card card_right project_card h-100 white-bg">
                               <div className="card-body right-box">
                                   {/* <div class="hover-img">
                                        <img src={himg2} alt="img" className="h-img6 h-img"/>
                                        <img src={himg2} alt="img" className="h-img8 h-img"/>
                                       
                                    </div> */}
                                <p className="text-blue head">For Users</p>
                                <p class="parg">
                                The fastest way to trade and farm tokens with low transaction fees. Earn high APY when you contribute liquidity to a pool. Put your token to work by staking to support and get rewarded by your favorite projects.
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </section>
              
<div className=''>
                <section id="sec_4" className="pt-5 gray-bg trad-with-swap d-none  d-md-block">
                    <div className="container container_custom">
                    <img src={globe_bg} className='img-fluid globe_bg'></img>

                        <div className="text-center pos_re_z">
                    <h3 className="why_text_tas title_text_why text-capitalize">Start Trading With MetaSwap and Enjoy...</h3>
                        {/* <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                        </div>
                        <div className="row mt-5 justify-content-between pos_re_z">
<div className="col-12 col-lg-5 col-lg-5-custon col-md-6 mb-4">
    <div className="card card_pgm mb-4">
        <div className="card-body d-flex">
        <img src={pgm1} className='img-fluid mr-2' />
        <div>
            <p>Best and Guaranteed Price</p>
            <p className="font_16 text_grey moretext" id="moretext_1">
                The trading price you see is the price you get for each of your orders because 
                every trade is cryptographically signed and guaranteed to fill at the specified price
                without any slippage during execution.
            </p>
       
        </div></div>

    </div>

    <div className="card card_pgm mb-4">
        <div className="card-body d-flex">
        <img src={pgm2} className='img-fluid mr-2' />
        <div> 
            <p>Mobile Friendly UI</p>
        <p className="font_16 text_grey moretext" id="moretext_2">
        MetaSwap is well designed and optimised for convenient
             mobile use on your smartphone. Easily connect your BEP20
            supported Wallet and interact with DApp with speed and ease.
        </p>
       
        </div>
        </div>

    </div>

    <div className="card card_pgm mb-4">
        <div className="card-body d-flex">
        <img src={pgm3} className='img-fluid mr-2' />
       <div>
           <p>Adequate Security and Control</p>
<p className="font_16 text_grey moretext" id="moretext_3">
    With MetaSwap you're 100% in control of crypto asset unlike centralized exchanges there is
    no need to register, submit vital details, and deposit funds that could expose your
    account to danger, our secured and audited smart contract execute your orders
    automatedly and securely.
</p>

        </div></div>

    </div>

</div>

<div className="col-12 col-lg-5 col-lg-5-custon col-md-6 mb-4">
    <div className="card card_pgm mb-4">
        <div className="card-body d-flex">
       <img src={pgm4} className='img-fluid mr-2' />
        <div>
        <p>Lightening Speed Processing</p>
        <p className="font_16 text_grey moretext" id="moretext_4">
            Your trades do not go through any order book so they are executed instantly 
            with publicly available confirmations on the blockchain to verify transaction status.
        </p>

        </div></div>

    </div>
    <div className='person_1'></div>
    {/* <img src={person_1} className='img-fluid person_1'/> */}
</div>
</div>


                        </div>
                        <div className="text-center py-5 pos_re_z">
    <a href="https://app.metaswap.click" class="btn_gradient btn_navbar_grad"><span>CONNECT WALLET</span></a>

        </div>
                </section>
                <section className=" pb-sm-0 pb-3 justify-content-around mobile-acc d-block d-md-none pos_re_z" >
                   
                    <div className="for-mobile container container_custom">
                    <div className="text-cus text-center ">
                    <h3 className="why_text_tas  title_text_why text-capitalize">Start Trading With MetaSwap and Enjoy...</h3>
                        {/* <p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                        </div>
                            <div className="mt-5">
                            <Accordion defaultActiveKey="0">
                            <Card onClick={() => this.changeIcon(0)}>
                                <Accordion.Toggle as={Card.Header} eventKey="0" >
                               
                                <img src={pgm1} className='img-fluid mr-2' />
                                Best and Guaranteed Price
                                <i class="fa fa-chevron-right" aria-hidden="true" id="icon_0"></i>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    
                                <Card.Body> 
The trading price you see is the price you get for each of your orders because every trade is cryptographically signed and guaranteed to fill at the specified price
without any slippage during execution.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card onClick={() => this.changeIcon(1)}>
                                <Accordion.Toggle as={Card.Header} eventKey="1" >
                                
                               

                                <img src={pgm2} className='img-fluid mr-2' /> Mobile Friendly UI
                                <i class="fa fa-chevron-right" aria-hidden="true" id="icon_1"></i>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>MetaSwap is well designed and optimised for convenient mobile use on your smartphone. Easily connect your BEP20
supported Wallet and interact with DApp with speed and ease.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card onClick={() => this.changeIcon(2)}>
                                <Accordion.Toggle as={Card.Header} eventKey="2" >
                                
                               

                                <img src={pgm3} className='img-fluid mr-2' /> Adequate Security and Control
                                <i class="fa fa-chevron-right" aria-hidden="true" id="icon_2"></i>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>With MetaSwap you're 100% in control of crypto asset unlike centralized exchanges there is no need to register, submit vital details, and deposit funds that could expose your account to danger, our secured and audited smart contract execute your orders automatedly and securely.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card onClick={() => this.changeIcon(3)}>
                                <Accordion.Toggle as={Card.Header} eventKey="3" >
                                
                               

                                <img src={pgm4} className='img-fluid mr-2' /> Lightening Speed Processing
                                <i class="fa fa-chevron-right" aria-hidden="true" id="icon_3"></i>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>
Your trades do not go through any order book so they are executed instantly with publicly available confirmations on the blockchain to verify transaction status.

                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            </Accordion>
                            </div>
                       
                        </div>
                        
                </section>
                <div className="text-center py-5 pos_re_z d-block d-md-none">
    <a href="https://app.metaswap.click" class="btn_gradient btn_navbar_grad"><span>CONNECT WALLET</span></a>

        </div>
       
</div>

<div className="container container_custom announcement_sec">


                   <p className="why_text_tas title_text_why">Announcements</p>
                    <div className="row mt-4 pt-3 justify-content-center pb-5">
                      <div className="col-lg-12 col-md-12">       
                     
                      <div class="h-card">
                            <img src={speaker} alt="img" className="h-img5 h-img"/>
                            <div className="card card_right project_card h-100 white-bg">
                               <div className="card-body right-box pt-announce-card">
                                 
                                <p class="parg">
                                $MASP will be making a major network update to provide cross-chain support for the Ethereum, Solana, and Avalanche chains.
                                </p>

                                <p class="parg">
                                The update will be deployed in phases, from the test net to the main net, over a period of 3 weeks starting from 01st/Jan/2023 00:00 (UTC) to around 22nd/Jan/2023 00:00 (UTC).
                                </p>

                                <p class="parg">
                                Our smart contracts will continue to run during this update, but we do not recommend doing any transactions or swaps due to the broad scope of the update's impact. 
If you must run them, please do so at your own risk.
                                </p>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
</div>


{/* <section className="bg-white carousel-sec">
    <div className="container container_custom">
        <p class="title text-center text-cus"><span className="fa fa-twitter mr-2"></span> MetaSwap Tweets</p>
        <OwlCarousel items={3}  
            className="owl-theme"  
            loop  
           
            nav={false} 
            margin={20} autoplay ={false} responsive={this.state.responsive} dots={true}>
                <div class="shade"> 
                    <div class="d-flex">
                        <div className="prof-img">
                        <a className="coin_a"><img src={token1} alt="" /></a>
                        </div>
                        <div class="cont">
                            <div className="prof-det">
                                <span><span className="font-weight-bold">Maximmilian</span> @maxjacobson .3h</span>
                            </div>
                            <p>Y'all ready for this next post?</p>
                            <ul>
                            <li>
                                <span className="fa fa-comment-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-retweet"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-heart-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-sign-out"></span>
                            </li>
                            </ul>
                        </div>
                    </div>   
            </div>  
            <div class="shade"> 
                    <div class="d-flex">
                        <div className="prof-img">
                        <a className="coin_a"><img src={token1} alt="" /></a>
                        </div>
                        <div class="cont">
                            <div className="prof-det">
                                <span><span className="font-weight-bold">Maximmilian</span> @maxjacobson .3h</span>
                            </div>
                            <p>Y'all ready for this next post?</p>
                            <ul>
                            <li>
                                <span className="fa fa-comment-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-retweet"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-heart-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-sign-out"></span>
                            </li>
                            </ul>
                        </div>
                    </div>   
            </div>  
            <div class="shade"> 
                    <div class="d-flex">
                        <div className="prof-img">
                        <a className="coin_a"><img src={token1} alt="" /></a>
                        </div>
                        <div class="cont">
                            <div className="prof-det">
                                <span><span className="font-weight-bold">Maximmilian</span> @maxjacobson .3h</span>
                            </div>
                            <p>Y'all ready for this next post?</p>
                            <ul>
                            <li>
                                <span className="fa fa-comment-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-retweet"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-heart-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-sign-out"></span>
                            </li>
                            </ul>
                        </div>
                    </div>   
            </div> 
            <div class="shade"> 
                    <div class="d-flex">
                        <div className="prof-img">
                        <a className="coin_a"><img src={token1} alt="" /></a>
                        </div>
                        <div class="cont">
                            <div className="prof-det">
                                <span><span className="font-weight-bold">Maximmilian</span> @maxjacobson .3h</span>
                            </div>
                            <p>Y'all ready for this next post?</p>
                            <ul>
                            <li>
                                <span className="fa fa-comment-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-retweet"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-heart-o"></span>
                                <span class="count">36</span>
                            </li>
                            <li>
                                <span className="fa fa-sign-out"></span>
                            </li>
                            </ul>
                        </div>
                    </div>   
            </div> 
            <div class="shade"> 
            <div class="d-flex">
                <div className="prof-img">
                <a className="coin_a"><img src={token7} alt="" /></a>
                </div>
                <div class="cont">
                    <div className="prof-det">
                        <span><span className="font-weight-bold">Maximmilian</span> @maxjacobson .3h</span>
                    </div>
                    <p>Y'all ready for this next post?</p>
                    <ul>
                    <li>
                        <span className="fa fa-comment-o"></span>
                        <span class="count">36</span>
                    </li>
                    <li>
                        <span className="fa fa-retweet"></span>
                        <span class="count">36</span>
                    </li>
                    <li>
                        <span className="fa fa-heart-o"></span>
                        <span class="count">36</span>
                    </li>
                    <li>
                        <span className="fa fa-sign-out"></span>
                    </li>
                    </ul>
                </div>
            </div> 
            </div>
            </OwlCarousel>
    </div>
</section> */}

              
                             
                             <Footer />
            </div>
        )
    }
}

export default Landing