import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import './App.css';

import Landing from './components/Landing';



function App() {
  return (
    <div>  
      <Router basename={'/'}>
	      <section className='container-fluid px-0'>
	      	<Switch>
	      <Route exact path='/' component={Landing} />

            <Route exact path='/Home' component={Landing} />
           

	      	</Switch>
	      </section>
      </Router>
    </div>
  );
}

export default App;
