import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import 'font-awesome/css/font-awesome.min.css';
import { Modal, Button, Dropdown } from 'react-bootstrap';

import '../css/header.css';
import logoIcon from "../images/logo-icon.png"
import logoText from "../images/logo-text-black.png"

import sun from "../images/sun.png"
import sun1 from "../images/sun1.png"
import moon from "../images/moon.png"
import moon1 from "../images/moon1.png"

import globe from "../images/globe.png"



class Header extends Component 
{
    darkTheme()
    {
        document.body.classList.remove('light_theme'); 
        document.body.classList.add('dark_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoWhite;
    }
    lightTheme()
    {
        document.body.classList.remove('dark_theme'); 
        document.body.classList.add('light_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoInner;
    }
      
    showMobile()
    {
        //  document.getElementById("mobile_nav_item").classList.toggle('active');
        document.getElementById("mobile_nav_item").classList.toggle("left_stye");
        document.getElementById("mobileLayer").classList.toggle('active');
        document.getElementById("burger").classList.toggle('clicked');  
        document.getElementById("burger").classList.toggle("burger_stye")   
        
    }
   
    
      
    
   constructor(props)
   {
       super(props);
       this.state = {location:false}
   }
  
   componentDidMount()
   { 
    document.getElementById("header").classList.remove('header-scrolled');
    document.body.classList.add('dark_theme'); 
    // const location = this.props.location.pathname.split('/')[1];
    // console.log("location",window.location.pathname.split('/')[1]);
    // const currentLocation = window.location.pathname.split('/')[1];
    // if(currentLocation == 'Home')
    // {
        window.addEventListener('scroll', function() {
            if(window.pageYOffset >80)
            {
                document.getElementById("header").classList.add('header-scrolled');
            }
            else{
                document.getElementById("header").classList.remove('header-scrolled');
 
            }
        });
    // }
    // console.log(this.state);
           
   }
    render() {
    const {location} = this.props;


		return (
           <div>
               <div id="mobileLayer">
                   <div id="header" className="fixed-top-navbar header-scrolled py-2 py-lg-2">
               <div className="container container_custom" id="mobileshow">
               <header className={`${location}`}>

<div className="d-flex align-items-center">

  
        <span className="logo_full mr-auto">
      

        </span>

    <nav className="nav-menu d-none d-lg-block">
          <ul>
          <li className="index-header-link"><a href="https://app.metaswap.click">Home</a></li>
          <li className="index-header-link"><a href="https://exchange.metaswap.click/#/swap">Exchange</a></li>
          <li className="index-header-link"><a href="https://exchange.metaswap.click/#/pool">Liquidity</a></li>


            <li className="index-header-link"><a href="https://app.metaswap.click/farms">Farms</a></li>
            <li className="index-header-link"><a href="https://app.metaswap.click/pools">Pools</a></li>
            <li className="index-header-link"><a href="https://app.metaswap.click/launchpad">Launchpad</a></li>
            <li className="index-header-link"><a href="https://app.metaswap.click/lockedstaking">Locked Staking</a></li>


            <li className="theme-icon">
            <div className="theme-btn">
                <span className="cursor_pointer icon_black fa fa-moon-o" onClick={this.darkTheme}></span>
         
            <span className="px-2 text-white slash_icon">/</span>
            <span className="cursor_pointer icon_black fa fa-sun-o" onClick={this.lightTheme}></span>
           </div> </li>
          

            <a href="https://app.metaswap.click" className="btn_gradient btn_navbar_grad" data-target="landcontact" id="reques">
                <span>USE APP</span></a>
        
        </ul>
    </nav>
     <a href="https://app.metaswap.click" class="btn_gradient btn_navbar_grad mobile-btn d-block d-lg-none mr-3"> <span>USE APP</span></a>
    <button type="button" className="mobile-nav-toggle d-lg-none" onClick={this.showMobile}><i className="fa fa-bars" aria-hidden="true" id="burger"></i></button>
   
    <nav className="mobile-nav d-lg-none" id="mobile_nav_item">
              <ul>
              <li className="mobile-link">
                   <a href="https://app.metaswap.click" class="btn_gradient btn_navbar_grad mr-3 mb-3"><span>CONNECT WALLET</span>
                   </a>
                   </li>
              <li className="index-header-link"><a href="https://app.metaswap.click"><span class="fa fa-home mr-3"></span>Home</a></li>
              <li className="index-header-link"><a href="https://exchange.metaswap.click/#/swap"><span class="fas fa-chart-bar mr-3"></span>Exchange</a></li>

              <li className="index-header-link"><a href="https://exchange.metaswap.click/#/pool"><span class="fas fa-plus-circle mr-3"></span>Liquidity</a></li>

            <li className="index-header-link"><a href="https://app.metaswap.click/farms"><span class="fas fa-tractor mr-3"></span>Farms</a></li>
            <li className="index-header-link"><a href="https://app.metaswap.click/pools"><span class="fas fa-swimming-pool mr-3"></span>Pools</a></li>
            <li className="index-header-link"><a href="https://app.metaswap.click/launchpad"><span class="fas fa-cubes mr-3"></span>Launchpad</a></li>

            <li className="index-header-link"><a href="https://app.metaswap.click/lockedstaking"><span class="fas fa-lock mr-3"></span>Locked Staking</a></li>
               
            
            
            
            <li>
            {/* <ul className="mobile-social mb-3">
                <li><a href="/" target="_blank" className=""><span className="fa fa-twitter"></span></a></li>
                <li><a href="/" target="_blank" className=""> <span className=" fa fa-paper-plane"></span> </a></li>
                <li><a href="/" target="_blank" className=""><span className="fa fa-file"></span></a></li>                
                <li><a href="/" target="_blank" className=""><span className="fa fa-envelope"></span></a></li>
            </ul> */}
            </li>
            <li className="theme-icon pl-3 mb-2 d-flex">
                <div className="theme-btn">
                    <span className="cursor_pointer icon_black fa fa-moon-o" onClick={this.darkTheme}></span>
                    
                        <span className="px-2 text-white slash_icon">/</span>
            <span className="cursor_pointer icon_black fa fa-sun-o" onClick={this.lightTheme}></span>
            </div>
           
                </li>
         

            
            </ul>
        </nav>

</div>


</header>
</div>
</div>
</div>

           </div>
        );
    }
}



export default Header